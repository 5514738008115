$(function() {

  // fixed 1st section scrool parallax
  $(document).on( 'scroll', function(){

    if ($(".hp--equal").length > 0) {
        var divOffset = $(".hp--equal").offset().top;
        var divHeight = $(".hp--equal").height();
        var WinHeight = $(window).height();
        var WinScroll = $(window).scrollTop();

        if ((WinHeight+WinScroll)>(divOffset+divHeight)) {
            $(".hp--equal").addClass('fixed');
        }else {
            $(".hp--equal").removeClass('fixed');
        }
    }

  });

  // Detect flex wrap header nav
  wrapped();

  $(window).resize(function() {
     wrapped();

    var site_header_height = $('#site-header').height();
     $('body:not(.no-header) #site').css("paddingTop",site_header_height);
  });

  function wrapped() {

    if($(window).width() >= 992) {

        var offset_top = $('.navigation .reveal .order-2').position().top;

        if (offset_top > 0) {
            $('.navigation .reveal .order-2').addClass('wrapped');
        } else {
            $('.navigation .reveal .order-2').removeClass('wrapped');
        }

    }

  }

  // Detect device & browser
  $(window).bind('load resize', function() {

    // BROWSER CLASS
    if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
        $("body").addClass("browser-safari");
    }
    if (navigator.userAgent.match(/iP(hone|od|ad)/i)) {
        $("body").removeClass("browser-safari");
        jQuery('body').addClass('browser-ios');
    }

    // Get browser
    $.each($.browser, function(i) {
        $('html').addClass(i);
        return false;
    });
    // Get OS
    var os = [
        'iphone',
        'ipad',
        'windows',
        'mac',
        'linux'
    ];
    var match = navigator.appVersion.toLowerCase().match(new RegExp(os.join('|')));
    if (match) {
        $('html').addClass(match[0]);
    };

  });

  // FIX PARALLAX
  // IE 11 background-attachment:fixed will jitter on scroll if this is missing
  jQuery(function() {
    if (navigator.userAgent.match(/MSIE 10/i) || navigator.userAgent.match(/Trident\/7\./) || navigator.userAgent.match(/Edge\/12\./)) {
        $('body').on("mousewheel", function() {
            event.preventDefault();
            var wd = event.wheelDelta;
            var csp = window.pageYOffset;
            window.scrollTo(0, csp - wd);
        });
    }
  });

  // ADD CLASS TO HTML ELEMENT WHEN THE MOBILE/BURGER NAVIGATION IS PRESENT
	(function($) {
    var $window = $(window),
        $html = $('html');

    function resize() {
        if ($window.width() < 992) {
            return $html.addClass('mobile-width');
        }
        $html.removeClass('mobile-width');
    }
    $window
        .resize(resize)
        .trigger('load resize');
  })(jQuery);


  // Main Navigation
	    $(".navigation nav.main ul li span").click(function() {
        if ($(".navigation nav.main ul li span").length) {
            $(this).parent().toggleClass("open-child");
            $(this).parent().siblings().removeClass("open-child");
            $(this).parent().toggleClass("open-child_mobile");
            $(this).parent().siblings().removeClass("open-child_mobile");
        } else {
            $(this).parent().toggleClass("open-child");
            $(this).parent().toggleClass("open-child_mobile");
        }
  });
  $(".navigation nav.main ul li.has-child.active").addClass("open-child_mobile");
    $("html").click(function() {
        $(".navigation nav.main ul li.open-child").removeClass("open-child");
    });
    $(".navigation nav.main ul li span, .navigation nav.main ul li span a, header a.expand").click(function(e) {
        e.stopPropagation();
    });

    // EXPAND MOBILE NAVIVAGTION
    $("header#site-header a.expand").click(function() {
        if ($(".navigation .reveal").length) {
            $("header#site-header a.expand").toggleClass('active');
            $("html").toggleClass('reveal-out');
        } else {
            $("header#site-header a.expand").toggleClass('active');
            $("html").toggleClass('reveal-out');
        }
    });


  // HEADER SCROLLING
	var didScroll;
	var lastScrollTop = 0;
	var delta = 60;
	var navbarHeight = $('header').outerHeight();

	// FIXED HEADER
	$(window).scroll(function(event) {
	    didScroll = true;
	    var scroll = $(window).scrollTop();
	    if (scroll >= 60) {
	        $("html").removeClass("reached-top");
	    } else {
	        $("html").addClass("reached-top").removeClass("nav-down").removeClass("nav-up");
	    }
	});
	setInterval(function() {
	    if (didScroll) {
	        hasScrolled();
	        didScroll = false;
	    }
	}, 5);

	function hasScrolled() {
	    var st = $(this).scrollTop();
	    // Make sure they scroll more than delta
	    if (Math.abs(lastScrollTop - st) <= delta)
	        return;
	    if (st > lastScrollTop && st > navbarHeight) {
	        // Scroll Down
	        $('html').removeClass('nav-down').addClass('nav-up');
	    } else {
	        // Scroll Up
	        $('html').addClass('nav-down').removeClass('nav-up');
	    }
	    lastScrollTop = st;
	}


  // Accordion
  let $accordion = $('.accordion');
  let $openBtn = $accordion.find('.btn.closed');
  let $btnWrapper = $accordion.find('.buttons-wrapper');

  $('.accordion').on('show.bs.collapse', function (e) {
      $(e.target).parent('.session').addClass('is-open');

      let totalItems = $('.accordion').find('.session').length;
      let totalItemsOpen = $('.accordion').find('.session.is-open').length;


      if (totalItems == totalItemsOpen) {
          $btnWrapper.addClass('is-open');
          $openBtn.removeClass('btn-success closed');
          $openBtn.addClass('btn-danger open');
          $openBtn.attr("title","Close all");
          $openBtn.find('.open-btn').addClass('d-none');
          $openBtn.find('.close-btn').removeClass('d-none');
      }
  })
  $('.accordion').on('hide.bs.collapse', function (e) {
      $(e.target).parent('.session').removeClass('is-open');

      $btnWrapper.removeClass('is-open');
      $btnWrapper.find('.btn').addClass('btn-success closed');
      $btnWrapper.find('.btn').removeClass('btn-danger open');
      $btnWrapper.find('.btn').attr("title","Open all");
      $btnWrapper.find('.btn').find('.open-btn').removeClass('d-none');
      $btnWrapper.find('.btn').find('.close-btn').addClass('d-none');

  })

 // Open and Close All
 $('body').on('click', '.btn.closed', function(e) {
    $('.accordion').find('.collapse').collapse('show');
    $('.accordion').find('.session').addClass('is-open');
    $('.accordion').find('.buttons-wrapper').addClass('is-open');
    $(this).removeClass('btn-success closed');
    $(this).addClass('btn-danger open');
    $(this).attr("title","Close all");
    $(this).find('.open-btn').addClass('d-none');
    $(this).find('.close-btn').removeClass('d-none');
});
$('body').on('click', '.btn.open', function(e) {
  $('.accordion').find('.collapse').collapse('hide');
  $('.accordion').find('.session').removeClass('is-open');
  $('.accordion').find('.buttons-wrapper').removeClass('is-open');
  $(this).addClass('btn-success closed');
  $(this).removeClass('btn-danger open');
  $(this).attr("title","Open all");
  $(this).find('.open-btn').removeClass('d-none');
  $(this).find('.close-btn').addClass('d-none');
});

  accessible_bootstrap_accordion();

  $('body').on('click', '.btn-modal', function(e) {
    e.preventDefault();
    e.stopPropagation();
    var popupID = $(this).data('target');

    $(popupID).modal('show');
});

// Quick Session register
$('body').on('click', '.session-register-btn', function(e) {
    e.preventDefault();
    e.stopPropagation();
    var urlRequest = $(this).data("service");
    var sessionKey = $(this).data("key");
    var popupID = $(this).parents(".modal").attr("id");
    var datas = "";
    var currentUrl = $(this).data("callback");
    var $data;
    var $htmlTarget = $(".agenda #results");

    datas += "sessions=" + sessionKey;

    $.ajax({
        url: urlRequest,
        type: "GET",
        traditional: true,
        async: false,
        cache: false,
        data: datas,
        dataType: "json",
        contentType: "application/json; charset=utf-8",
        success: function (data) {
                // Close popup
                $('#' + popupID).modal('hide');

                //Refresh agenda
                $.get(currentUrl, function (data) {
                    $data = $(data).find(".agenda #results");
                    $htmlTarget.html($data.html());

                    $(".session-register-btn").attr("data-callBack", currentUrl);
                })

        },
        failure: function (msg) {
            alert("Error to execute action. Please retry.");
        }
    });

});


  // Flex speaker avatar agenda
  var speakers = $('.card-speakers-sum'); //select flex wrap and wrap-reverse elements

    if (speakers.length) { //don't add listener if no flex elements
        $(window)
            .on('resize', function() {
                speakers.each(function() {
                    var prnt = $(this),
                        chldrn = prnt.children(':not(:first-child)'), //select flex items
                        frst = prnt.children().first();

                    if (chldrn.length > 0) {
                        chldrn.each(function(i, e) { $(e).toggleClass('flex-wrapped invisible', $(e).offset().top != frst.offset().top); }); //element has wrapped
                        prnt.toggleClass('flex-wrapping', !!frst.find('.flex-wrapped').length); //wrapping has started
                        frst.toggleClass('flex-wrapped', !!!chldrn.filter(':not(.flex-wrapped)').length); //all are wrapped
                    }

                    if(prnt.find('.flex-wrapped').length) {
                        var $addedDiv = prnt.find(".speaker.speaker--more");

                        if ($addedDiv.length == 0) {
                            prnt.prepend('<div class="speaker speaker--more"></div>');
                            addCount(prnt);
                        }else {
                            addCount(prnt);
                        }
                    }else {
                        prnt.find(".speaker.speaker--more").remove();
                    }

                    function addCount(prnt) {
                        var count = prnt.find('.flex-wrapped').length;
                        prnt.find(".speaker.speaker--more").html("+"+ count);
                    }
            });
            })
            .trigger('resize'); //lazy way to initially call the above
    }


  // Live select
  (function () {
        var previous;

        $("#live-select").on('focus', function () {
            // Store the current value on focus and on change
            previous = this.value;
        }).change(function(e) {
            var live = this.value;
            e.stopPropagation();

            $('.' + previous).addClass('d-none');
            $('.' + live).removeClass('d-none');

            var iFrame = $('.' + previous).find('iframe[src*="vimeo.com"]');
            var iFrameSRC = iFrame.attr('src');
            iFrame.attr('src','');
            iFrame.attr('src', iFrameSRC);


            // Make sure the previous value is updated
            previous = this.value;
        });
    })();


    // Alert close Bootstrap
    $('.alert').alert();

});

// Tooltip
$(function () {
  if(isTouchDevice()===false) {
    $('body').tooltip({selector: '[data-toggle="tooltip"]'});
  }
})

// Improve scroll performance of animation
function debounce(func, wait, immediate) {
  var timeout;
  return function() {
      var context = this,
          args = arguments;
      var later = function() {
          timeout = null;
          if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
  };
};
var myEfficientScroll = debounce(function() {
  // Trigger animations
  // http://www.oxygenna.com/tutorials/scroll-animations-using-waypoints-js-animate-css
  function onScrollInit(items, trigger) {
      items.each(function() {
          var osElement = $(this),
              osAnimationClass = osElement.attr('data-os-animation'),
              osAnimationDelay = osElement.attr('data-os-animation-delay');
          osElement.css({
              '-webkit-animation-delay': osAnimationDelay,
              'animation-delay': osAnimationDelay
          });
          var osTrigger = (trigger) ? trigger : osElement;
          osTrigger.waypoint(function() {
              osElement.addClass('animate__animated').addClass(osAnimationClass);
          }, {
              triggerOnce: true,
              offset: '90%'
          });
      });
  }
  onScrollInit($('.os-animation'));
}, 250);
window.addEventListener('load', myEfficientScroll);

// Accessible  -  Define values for keycodes
function keyCodes() {
    this.tab = 9;
    this.untab = 16;
    this.enter = 13;
    this.escape = 27;
    this.space = 32;
    this.end = 35;
    this.home = 36;
    this.left = 37;
    this.up = 38;
    this.right = 39;
    this.down = 40;
}

// Accessible - bootstrap accordion
function accessible_bootstrap_accordion() {
    var keys = new keyCodes();

    // Tabs nav links
    $(document).on('keydown', '.accordion [data-toggle="collapse"]', function (e) {
      var currentBtn = $(this),
          currentCardWrapper = currentBtn.parents('.card');

      function next() {
        $(currentCardWrapper.next().find('[data-toggle="collapse"]').focus().attr('data-target'));
      }
      function prev() {
        $(currentCardWrapper.prev().find('[data-toggle="collapse"]').focus().attr('data-target'));
      }

      if (e.which === keys.enter || e.which === keys.space) {
        e.preventDefault(); // Disable arrow key scrolling
        $(currentBtn.attr('data-target')).collapse('toggle');
      }

      if (e.which === keys.down) {
        e.preventDefault(); // Disable arrow key scrolling
        $(currentBtn.attr('data-target')).collapse('hide');

        if (currentCardWrapper.next('.card').length) {
            next();
        }
      }

      if (e.which === keys.up) {
        e.preventDefault(); // Disable arrow key scrolling
        $(currentBtn.attr('data-target')).collapse('hide');

        if (currentCardWrapper.prev('.card').length) {
            prev();
        }
      }

    });
  }


// Is Touche Device ?
function isTouchDevice(){
  return true == ("ontouchstart" in window || window.DocumentTouch && document instanceof DocumentTouch);
}


// Favorites
var love = function () {
    $(".btn--favourites").click(function (ev) {
        var $this = $(this);
        ev.preventDefault();
        $.get($this.attr("href"), function (data) {
            $this.removeClass("added removed").addClass(data);
        });

    });
};
love();