// BANNER
$(function() {
    // Image
    $('.banner [class$="-banner"]').each(function () {

        if(!$(this).parents(".slides").length > 0) {

            // Link text color
            var $text_color = $(this).find(".text").attr("style");
            $(this).find(".text a:not(.btn)").attr("style", $text_color);
        }

    });

    // Sliders
    $('.banner .slides').on('init', function(slick){
        $('video').each(function () {
            this.play();
        });

        // Navigation color
        var $slick_nav = $(this).find(".slick-slide.slick-active").attr("data-nav-color");
        $(this).find(".slick-arrow").css("color", $slick_nav);
        $(this).find(".slick-dots li > button").css("background-color", $slick_nav);

        $('.slick-slide').each(function () {
            // Link text color
            var $text_color = $(this).find(".text").attr("style");
            $(this).find(".text a:not(.btn)").attr("style", $text_color);
        });

        var $animattion = $(this).find(".slick-slide:not(.slick-active) .os-animation")

        $animattion.each(function () {
            var $this = $(this);

            $this.addClass("invisible");

        });


    });
    // ALL CAROUSELS
    $("section:not(.banner) .slides").slick({
        rtl: rtl_slick(),
        infinite: true,
        speed: 600,
        adaptiveHeight: true,
        prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" aria-disabled="true" style=""><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/></svg></button>',
        nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="" aria-disabled="false"><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/></svg></button>'
    });
    $(".banner .slides").slick({
        rtl: rtl_slick(),
        infinite: true,
        speed: 600,
        fade: true,
        adaptiveHeight: true,
        prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" aria-disabled="true" style=""><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/></svg></button>',
        nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="" aria-disabled="false"><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/></svg></button>'
    });
    $('.banner .slides').on('afterChange', function(slick){

        // Navigation color
        var $slick_nav = $(this).find(".slick-slide.slick-active").attr("data-nav-color");
        $(this).find(".slick-arrow").css("color", $slick_nav);
        $(this).find(".slick-dots li > button").css("background-color", $slick_nav);

        var $animation = $(this).find(".slick-slide.slick-active .os-animation")

        $animation.each(function (index, element) {
            var $this = $(this);
            var osAnimationClass = $this.attr('data-os-animation');
            var osAnimationDelay = $this.attr('data-os-animation-delay');

            $this.removeClass("invisible");
            $this.removeClass("animate__animated").removeClass(osAnimationClass);

            setTimeout(() => {
                $this.css({
                    '-webkit-animation-delay': osAnimationDelay,
                    'animation-delay': osAnimationDelay
                });
                $this.removeClass("animate__animated").removeClass(osAnimationClass).addClass("animate__animated").addClass(osAnimationClass);

            }, 100);
        });




    });

    $('.banner .slides').on('beforeChange', function(slick){
        var $animation = $(this).find(".slick-slide.slick-active .os-animation");

        $animation.each(function (index, element) {
            var $this = $(this);
            var osAnimationClass = $this.attr('data-os-animation');

            $this.removeClass("animate__animated").removeClass(osAnimationClass);
        });

        var $animation2 = $(this).find(".slick-slide:not(.slick-active) .os-animation");

        $animation2.each(function (index, element) {
            var $this = $(this);
            $(this).find(".slick-slide:not(.slick-active) .os-animation").addClass("invisible");

        });

    });

    //RTL for Slick
    function rtl_slick(){
        if($("html").is('[dir="rtl"]')) {
            return true;
        } else {
            return false;
    }}

});